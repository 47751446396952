import { Footer } from "Partials/Footer"
import { HTMLAttributes, ReactNode } from "react"
import { PageRoute } from "services/router/routerService"

import styles from "./styles.module.scss"

type ContentProps = HTMLAttributes<HTMLElement> & {
  header?: ReactNode
  children: ReactNode | ReactNode[] | string
  navigationRoutes: PageRoute[]
  showFooter?: boolean
}

export const Content = ({ header, children, navigationRoutes, className, showFooter = true, ...props }: ContentProps) => {

  return (
    <>
      {header}
      <main className={styles.main}>
        <section {...props} className={[className].join(" ")}>
          {children}
        </section>
        {showFooter && <Footer navigationRoutes={navigationRoutes} />}
      </main>
    </>
  )
}
