import { useTranslation } from "next-i18next"
import { ConfigurableEvent } from "./types"
import { MultiLanguageString } from "types/utils"
import { i18nText } from "utils/i18nUtils"

import styles from "./styles.module.scss"
import { SectionDescription } from "../SectionDescription"
import { Link } from "../Link"
import { forwardRef, LegacyRef } from "react"
import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { useRouter } from "next/router"

type SubSectionProps = {
  title: string|MultiLanguageString
  description?: string|MultiLanguageString
  descriptionNumberOfLines?: number
  see_all?: SubSectionSeeAll
  children?: JSX.Element|JSX.Element[]
  className?: string
  onActionClickEvent?: (event: ConfigurableEvent) => void
}

type SubSectionSeeAll = {
  text: MultiLanguageString
  link: string
  event: ConfigurableEvent
}

export const SubSection = forwardRef<HTMLInputElement, SubSectionProps>((
  { title, children, className, description, descriptionNumberOfLines, see_all, onActionClickEvent },
  ref
) => {

  const { i18n } = useDelirecTranslation()
  const router = useRouter()

  const i18nTitle = typeof title === "string" ? title : i18nText(i18n, title)
  const sellAllText = i18nText(i18n, see_all?.text)
  const descriptionText = i18nText(i18n, description)

  return (
    <div className={`${styles.container} ${className}`} ref={ref}>
      <div className={styles.title}>
        <div>
          <h2 className={styles.titleText}>{i18nTitle}</h2>
          {see_all && (
            <div onClick={() => onActionClickEvent?.(see_all.event)}  className={styles.seeAll}>
              <Link 
                href={see_all.link}
                className={styles.titleAction}
              >
                {sellAllText}
              </Link>
            </div>
          )}
        </div>
        <div />
      </div>
      { descriptionText && (
        <SectionDescription
          className={styles.description}
          readMoreLines={descriptionNumberOfLines}
        >
          {descriptionText}
        </SectionDescription>
      ) }
      {children}
    </div>
  )
})
