import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { useEffect, useState } from "react"

import styles from "./styles.module.scss"

type SectionDescriptionType = {
  children: string
  className?: string
  readMoreLines?: number
}

export function SectionDescription({ children, className, readMoreLines = 3 }: SectionDescriptionType) {

  const [readMore, setReadMore] = useState(false)
  const { t } = useDelirecTranslation()

  function toggleReadMore() {
    setReadMore(!readMore)
  }

  useEffect(() => {
    setReadMore(false)
  }, [children])

  return (
    <div className={[styles.container, className].join(" ")}>
      <div 
        className={[styles.readMoreText, !readMore && styles.readMoreCrop, styles[`readMoreCrop${readMoreLines}`]].join(" ")}
        dangerouslySetInnerHTML={{ __html: children }}
      />
      <p 
        className={styles.readMoreButtons}
        onClick={toggleReadMore}
      >
        {readMore ? ` ${t("buttonActions.readLess")}` : ` ${t("buttonActions.readMore")}`}
      </p>
    </div>
  )
}