import { DeliRecPages } from "utils/routes"
import { EventActions, EventNames, sendEvent } from "."

type RecipeViewParams = {
  [key:string]: any
  source?: DeliRecPages
  source_context?: string
  recipe_id: string
}

function sendRecipeClickEvent(params: RecipeViewParams) {
  sendEvent({
    name: EventNames.recipe_view,
    action: EventActions.click,
    params: params
  })
}

type ChefViewParams = {
  [key:string]: any
  source?: DeliRecPages
  source_context?: string
  chef_id: string
}

function sendChefEventClick(params: ChefViewParams) {
  sendEvent({
    name: EventNames.chefs,
    action: EventActions.click,
    params: params
  })
}

type CategoryClickParams = {
  [key:string]: any
  source: string
  source_context: string
  category_id: string
}

function sendCategoryClick(params: CategoryClickParams) {
  sendEvent({
    name: EventNames.categories,
    action: EventActions.click,
    params: params
  })
}

type UrlClickParams = {
  [key:string]: any
  source: string
  source_context: string
  url: string
}

function sendUrlClick(params: UrlClickParams) {
  sendEvent({
    name: EventNames.external_link,
    action: EventActions.click,
    params: params
  })
}

export { 
  sendRecipeClickEvent, sendCategoryClick, sendUrlClick,
  sendChefEventClick
}