import { useTranslation } from "next-i18next"

import styles from "./styles.module.scss"

function Copyright() {
  const { t } = useTranslation("common")

  return (
    <p className={styles.copyright}>
      <>
        &copy; {new Date().getFullYear()} {t('oauth.copyright')}
      </>
    </p>
  )
}

export { Copyright }