import { useMemo } from "react";
import { DeliRecPages } from "utils/routes";

export const recipeEventPath = (path: string, source: DeliRecPages, source_context: string) => {
  return `${path}?source=${source}&source_context=${source_context}`;
}

export const useRecipeEventPath = (path: string, source: DeliRecPages, source_context: string) => {
  return useMemo(() => {
    return `${path}?source=${source}&source_context=${source_context}`;
  }, [path, source, source_context]);
}

export const useUserEventPath = (path: string, source: DeliRecPages, source_context: string) => {
  return useMemo(() => {
    return `${path}?source=${source}&source_context=${source_context}`;
  }, [path, source, source_context]);
}

type Queries = {
  source?: DeliRecPages;
  source_context?: string;
}

type QueryEventResult = {
  pathname: string
  search: string
}

type EventFn = (source?: DeliRecPages, source_context?: string) => void

export const logQueryEvent = (queries: Queries, path: string, event: EventFn): QueryEventResult | undefined => {
  if (queries.source && queries.source_context) {
    const cleanedPath = path.indexOf("?") > -1 ? path.split("?")[0] : path
    const pathQueries = path.indexOf("?") > -1 ? `?${path.split("?")[1]}` : ""

    const queryParams = new URLSearchParams(pathQueries)

    event(queries.source, queries.source_context)

    queryParams.delete("source")
    queryParams.delete("source_context")

    return { pathname: cleanedPath, search: queryParams.toString() }
  } else {
    event()
  }
  return undefined
}