import { AxiosError } from "axios"
import { useState } from "react"
import { SubmitErrorHandler, useForm } from "react-hook-form"
import { useTranslation } from "next-i18next"
import * as Sentry from "@sentry/nextjs"

import { addUserToNewsletter } from "services/newsletter/newsletterService"
import { emailValidation } from "utils/validationPatterns"

import styles from "./styles.module.scss"
import { useDelirecTranslation } from "hooks/useDelirecTranslation"

type NewsletterMessage = {
  status: "success" | "error" | "loading",
  message: string
}

interface FormInputs {
  name: string
  email: string
}

function FormEmailInput() {

  const { t } = useDelirecTranslation()
  const { register, handleSubmit, formState: { errors } } = useForm<FormInputs>()
  
  const [message, setMessage] = useState<NewsletterMessage>()

  const messageClass = message?.status === "success" 
    ? styles.messageSuccess
    : message?.status === "error"
      ? styles.messageError
      : message?.status === "loading"
        ? styles.messageLoading
        : undefined

  const handleSubmitError: SubmitErrorHandler<FormInputs> = (errors) => {
    if (errors.name && errors.email) {
      setMessage({
        status: "error",
        message: t("footer.newsletter_error_all_fields")
      })
    } else if (errors.name) {
      setMessage({
        status: "error",
        message: t("footer.newsletter_error_name_field")
      })
    } else if (errors.email) {
      setMessage({
        status: "error",
        message: t("footer.newsletter_error_email_field")
      })
    }
  }

  async function handleSubmitEmailToNewsletter(inputs: FormInputs) {
    if (message?.status === "loading") {
      return;
    }
    setMessage({
      status: "loading",
      message: t("footer.newsletter_loading")
    })
    try {
        await addUserToNewsletter(inputs.name, inputs.email)

        setMessage({
        status: "success",
        message: t("footer.newsletter_success")
      })
    } catch (error) {
      Sentry.captureException(error)
      const _err = error as AxiosError

      switch (_err.response!.status) {
        case 409:
          setMessage({
            status: "success",
            message: t("footer.newsletter_success")
          })
        break;
        case 400:
          setMessage({
            status: "error",
            message: t("footer.newsletter_error_all_fields")
          })
        break;
        default:
          setMessage({
            status: "error",
            message: t("footer.newsletter_error_api")
          })
        break;
      }
    }
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit(handleSubmitEmailToNewsletter, handleSubmitError)}>
      <input
        placeholder={t("footer.newsletter_name")}
        className={`${styles.inputBase} ${errors.name ? styles.inputError : ""}`}
        {...register("name", { required: true })}
      />
      <div className={styles.emailContainer}>
        <input
          placeholder={t("footer.newsletter_email")}
          className={`${styles.inputBase} ${errors.email ? styles.inputError : ""}`}
          {...register("email", { required: true, pattern: emailValidation })}
        />
        <button type="submit">
          {t("buttonActions.ok")}
        </button>
      </div>
      {message && (
        <span className={`${styles.message} ${messageClass}`}>{message.message}</span>
      )}
    </form>
  )
}

export { FormEmailInput }