import { LegacyRef, useMemo } from "react"

import { Image } from "components/shared/Image"

import { IRecipeItem } from "types/recipe"

import { recipePlaceholder } from "utils/placeholders"

import styles from "./styles.module.scss"
import { chefUrl, DeliRecPages } from "utils/routes"
import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { Link } from "components/shared/Link"
import { useRouter } from "next/router"
import { useRecipeEventPath, useUserEventPath } from "utils/events/utils"

interface RecipeItemDataOutsideCoverProps extends IRecipeItem {
  className?: string
  classNameGrid?: string
  ref?: LegacyRef<any>
  alt?: string,
  source: DeliRecPages
  source_context: string
}

function RecipeItemDataOutsideCover({
  className, classNameGrid, ref, image, imageType, name, chefUsername,
  path, alt, source, source_context
}: RecipeItemDataOutsideCoverProps) {

  const { t } = useDelirecTranslation()
  const _alt = alt ? `${name}, ${alt}` : t("recipe.cell_image_alt", { name })
  const grid = classNameGrid ? classNameGrid : ''
  const router = useRouter()

  const recipePath = useRecipeEventPath(path, source, source_context)
  const userPath = useUserEventPath(chefUrl(chefUsername, router), source, source_context)

  return (
    <div className={`${styles.container} ${grid}`} ref={ref}>
      <div className={className}>
        <Link 
          href={recipePath}
          className={styles.imageContainer}
        >
          <Image 
            src={image?.mobile}
            className={styles.image}
            placeholder={recipePlaceholder}
            alt={_alt}
          />
          {imageType === "video_thumb" && (
            <img 
              className={styles.videoTag}
              src="/assets/recipes/video_tag.svg"
            />
          )}
        </Link>
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{name}</p>
        <div className={styles.actionContainer}>
          <p>{t("recipeItem.by")}&nbsp;</p>
          <div>
            <Link href={userPath}>
              {`@${chefUsername}`}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export { RecipeItemDataOutsideCover }