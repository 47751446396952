import { LegacyRef, MouseEvent } from "react"
import { IRecipeItem } from "types/recipe"
import { recipePlaceholder } from "utils/placeholders"
import styles from "./styles.module.scss"
import { Image } from "components/shared/Image"
import { chefUrl, DeliRecPages } from "utils/routes"
import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { Link } from "components/shared/Link"
import { useRouter } from "next/router"
import { useRecipeEventPath, useUserEventPath } from "utils/events/utils"

interface RecipeItemDataInCoverProps extends IRecipeItem {
  className?: string
  ref?: LegacyRef<any>
  alt?: string
  source: DeliRecPages
  source_context: string
}

function RecipeItemDataInCover({ 
  name, image, imageType, chefUsername,
  className, ref, path, alt, source, source_context
 }: RecipeItemDataInCoverProps) {

  const { t } = useDelirecTranslation()
  const _alt = alt ? `${name}, ${alt}` : t("recipe.cell_image_alt", { name })
  const router = useRouter()

  const recipePath = useRecipeEventPath(path, source, source_context)
  const userPath = useUserEventPath(chefUrl(chefUsername, router), source, source_context)

  return (
    <div className={`${styles.container} ${className}`} ref={ref}>
      <div>
        <Link 
          href={recipePath}
          className={styles.imageLink}
        >
          <div className={styles.imageContainer}>
            <Image
              src={image?.mobile}
              placeholder={recipePlaceholder}
              className={styles.image}
              alt={_alt}
            />
            {imageType === "video_thumb" && (
              <img 
                className={styles.videoTag}
                src="/assets/recipes/video_tag.svg"
              />
            )}
            <div className={styles.background} />
          </div>
        </Link>
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{name}</p>
        <div className={styles.actionContainer}>
          <p>{t("recipeItem.by")}&nbsp;</p>
          <Link 
            href={userPath}
          >
            {`@${chefUsername}`}
          </Link>
        </div>
      </div>
    </div>
  )
}

export { RecipeItemDataInCover }