import styles from "./styles.module.scss"

type OutlineButtonProps = {
  size?: "default" | "mini"
  children: string | JSX.Element
  className?: string
  onClick?: () => void
}

function OutlineButton({ size = "default", children, onClick, className }: OutlineButtonProps) {

  const sizeStyles = {
    mini: styles.mini,
    default: ""
  }

  return (
    <button
      onClick={onClick}
      className={`${styles.container} ${sizeStyles[size]} ${className}`}
    >
      {typeof children === "string" ? <p>{children}</p> : children}
    </button>
  )
}

export { OutlineButton }