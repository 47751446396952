import { useBreakpoints } from "hooks/useScreen"
import { LegacyRef } from "react"

import { IRecipeItem } from "types/recipe"
import { DeliRecPages } from "utils/routes"

import { RecipeItemDataInCover } from "../RecipeItemDataInCover"
import { RecipeItemDataOutsideCover } from "../RecipeItemDataOutsideCover"

import styles from "./styles.module.scss"
interface RecipeItemAdaptiveProps extends IRecipeItem {
  className?: string
  classNameGrid?: string
  ref?: LegacyRef<any>
  alt?: string
  source: DeliRecPages
  source_context: string
}

function RecipeItemAdaptive(props: RecipeItemAdaptiveProps) {

  const { isDesktop } = useBreakpoints()

  if (isDesktop) {
    return <RecipeItemDataInCover {...props} />
  }

  return <RecipeItemDataOutsideCover {...props} />
}

export { RecipeItemAdaptive }