import { LegacyRef } from "react"
import Slider from "react-slick"

import styles from "./styles.module.scss"

export interface IHorizontalGridRef extends Slider {

}

type HorizontalGridProps = {
  columns: number
  children: JSX.Element | JSX.Element[]
  rows?: number
  spaceBetweenItens?: 0 | 8 | 16 | 20 | 26 | 32
  sliderRef?: LegacyRef<IHorizontalGridRef>
  withBorder?: boolean
  handleChangePage?: (pageIndex: number) => void
  className?: string
  vertical?: boolean
  rowClassName?: string
}

function HorizontalGrid({
  rows = 1, columns, spaceBetweenItens, children, vertical,
  handleChangePage, sliderRef, withBorder, className, rowClassName
}: HorizontalGridProps) {

  return (
    <Slider
      rows={rows}
      dots={false}
      slidesToShow={columns}
      slidesToScroll={Math.floor(columns)}
      afterChange={handleChangePage}
      infinite={false}
      ref={sliderRef}
      vertical={vertical}
      className={`
        ${className} ${styles.container}
        ${spaceBetweenItens && styles[`itemsGap${spaceBetweenItens}`]}
        ${withBorder && styles.withBorder}
      `}
    >
      {children}
    </Slider>
  )
}

export { HorizontalGrid }