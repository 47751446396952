import { useRouter } from "next/router"

import { Link } from "components/shared/Link"

import { PageRoute } from "services/router/routerService"

import { FormEmailInput } from "Partials/Footer/components/FormEmailInput"

import { useDelirecTranslation } from "hooks/useDelirecTranslation"

import { useNavigationRoutePath, supportUrl } from "utils/routes"

import { Copyright } from "./components/Copyright"

import styles from "./styles.module.scss"

type FooterProps = {
  navigationRoutes: PageRoute[]
}

function Footer({ navigationRoutes }: FooterProps) {

  const { t } = useDelirecTranslation()
  const router = useRouter()

  const homePath = useNavigationRoutePath(router, "home", navigationRoutes)
  const termsPath = useNavigationRoutePath(router, "terms", navigationRoutes)
  const privacyPath = useNavigationRoutePath(router, "privacy", navigationRoutes)

  return (
    <>
      <footer className={styles.footer}>
        <Link href={homePath}>
          <img
            src="/assets/delirec/delirec_logo_blue.svg"
            alt="DeliRec logo"
            className={styles.logo}
          />
        </Link>
        <div className={styles.sections}>
          <div>
            <p className={styles.delirecDescription}>
              {t("footer.about")}
            </p>
            <p className={styles.newsLetterSignIn}>{t("footer.subscribe_newsletter")}</p>
            <FormEmailInput />
          </div>
          <div className={styles.links}>
            <div>
              <p>{t("footer.important_links")}</p>
              <Link href={termsPath}>{t("terms")}</Link>
              <Link href={privacyPath}>{t("privacy")}</Link>
              <a href={supportUrl}>
                {t("help_central")}
              </a>
            </div>
          </div>
          <div className={styles.socialLinksContainer}>
            <div className={styles.socialLinksContent}>
              <div>
                <p>{t("download_app")}</p>
                <div className={styles.storeTags}>
                  <a href="https://delirec.page.link/apple_store">
                    <img
                      className="appstore"
                      src="/assets/storeTags/app_store_tag.svg"
                      alt="appstore"
                    />
                  </a>
                  <a href="https://delirec.page.link/google_play">
                    <img
                      className="playstore"
                      src="/assets/storeTags/google_play_tag.svg"
                      alt="playstore"
                    />
                  </a>
                </div>
              </div>
              <div>
                <p>{t("follow_us")}</p>
                <div className={styles.socialNetworks}>
                  <a href="https://www.facebook.com/delirec.br">
                    <img src="/assets/socialIcons/facebook_icon.svg" alt="Facebook" />
                  </a>
                  <a href="https://www.instagram.com/delirec.br">
                    <img src="/assets/socialIcons/instagram_icon.svg" alt="Instagram" />
                  </a>
                </div>
              </div>
            </div>
            </div>
        </div>
        <Copyright />
      </footer>
    </>
  )
}

export { Footer }